import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Posts from "../components/posts/posts"

const BlogPage = ({ data }) => (
  <Layout
    heroFade
    heroCover={data.cover}
    heroContent={
      <>
        <h1 className="huge">
          <strong className="text-primary">Blog</strong>
        </h1>
        <h3>Here I post what I learned during projects.</h3>
        <h3>And share it with the world!</h3>
      </>
    }
  >
    <SEO title="Blog" />
    <Posts id="posts" posts={data.allMdx.posts} />
  </Layout>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "pluto.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { in: "blog" } } }
    ) {
      posts: edges {
        node {
          id
          frontmatter {
            title
            slug
            date(formatString: "MMM DD, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
